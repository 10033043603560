<template>
  <div>
    <div class="text-left">
      <div class=" mt-2">
        <div class=" card p-3 d-flex align-items-center flex-row  justify-content-between">
          <h3>Wallet Entries</h3>
          <div class="">
            <h5>Wallet Balance <span class="font-weight-bold text-uppercase">${{wallet_balance || '0.0'}}</span> </h5>
            <button
              class="btn btn-primary rounded-0 btn-md ml-2"
              data-toggle="modal"
              data-target="#walletModal"
            >
              Topup Wallet
            </button>
          </div>
        </div>

        

        <div class=" my-2  uploads-table-custom ">
            
          <div class=" order-listing-header  row my-2  p-2 mx-sm-0 mx-1 ">  

              <div class="col-3 text-left ">Date</div>
              <div class="col-5 text-left">Description</div>
              <div class="col-2 text-left"> Credit</div>
              <div class="col-2 text-left">Debit</div>
              <!-- <div class="col-2 text-left">Action </div> -->
          </div>


        <div v-if="wallet_entries.length > 0"   class="">
        <div
        v-for="(wallet, index) in wallet_entries"
                :key="index + 'ords'"
        class="order-listing-body row  mx-sm-0 ">  
            <div style="font-weight: 400!important;" class="col-3  d-flex align-items-center justify-content-start ">
              
              {{ wallet.create_stamp || "" }}
              
            </div>
            <div style="font-weight: 400!important;" class="col-5 d-flex align-items-center justify-content-start text-left">
              {{ wallet.description || "" }}
            </div>
            <div class="col-2 d-flex align-items-center justify-content-start darken--4 teal lighten-5 text-left">${{ wallet.credit || "" }}</div>
            <div style="font-weight: 400!important;" class="col-2 d-flex darken--4 py-3 error lighten-5 align-items-center justify-content-start text-left"
            
            >   
            ${{ wallet.debit || "" }}
              
            </div>
          
        </div>

        </div>



        </div>
      </div>
    </div>

    <BaseModal :title="'Wallet Topup'" :modalId="'walletModal'">
      <WalletTopup
        @topupsuccess="getWalletEntries"
        :user_id="user_id"
      ></WalletTopup>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import WalletTopup from "../../components/payments/modals/WalletTopup.vue";
export default {
  name: "Wallet",
  components: {
    WalletTopup,
  },
  props: {
    user_id: {
      type: String,
    },
  },

  computed: {
    ...mapState("payment", ["wallet_entries"]),
    ...mapGetters('writers', ['wallet_balance'])
   
  },

  methods: {
    ...mapActions("payment", ["_getWalletEntries"]),
    async getWalletEntries() {
      await this._getWalletEntries(this.user_id);
    },
  },

  async mounted() {
    this.getWalletEntries();
  },
};
</script>

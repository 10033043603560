<template>
  <div class="text-left">
    <form class="col-12" @submit.prevent="topUpWallet">
      <BaseLoading style="min-height: 100px" v-if="loading"></BaseLoading>
      <div v-else>
        <div class="form-group">
          <label for="">Amount</label>
          <input
            v-model="amount"
            class="form-control"
            id="inputEmail3"
            placeholder="Enter amount"
            required="required"
            type="number"
            step="any"
          />
        </div>

        <div class="my-3 form-group">
          <label for="">Topup Description</label>
          <textarea
            v-model="description"
            class="form-control"
            id="inputEmail3"
            placeholder="Reason for topup.."
            required="required"
          ></textarea>
        </div>
      </div>
      <button :disabled="loading" class="btn btn-primary mt-2" type="submit">
        Topup Wallet
      </button>
    </form>
  </div>
</template>

<script>
import http from "../../../modules/http.index";
export default {
  data: () => ({
    loading: false,
    amount: "",
    description: "",
  }),
  props: {
    user_id: {
      type: String,
    },
  },
  methods: {
    topUpWallet() {
      let data = {
        amount: this.amount,
        description: this.description,
        user_id: this.user_id,
      };
      this.loading = true;
      http
        .post("/Payments/WalletEntry", data)
        .then(() => {
          // this.$notify({
          //   title: "Error",
          //   text: "Wallet topup successiful",
          //   style: "success",
          // });

          this.$swal({
              icon: 'success',
              title: 'Success',
              text:  "Wallet topup successiful",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
          this.$emit("topupsuccess");
        })
        .catch((err) => {
         

          this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data
              ? err.response.data.Message
              : "Could not top up wallet",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
          console.log(err);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>